<template>
  <div class="account">
    <el-form
      :model="account"
      :rules="rules"
      ref="payInfo"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="验证码"
        prop="captcha"
        placeholder="请输入图形验证码"
      >
        <el-input v-model="account.captcha" style="width: 200px" />
        <el-image
          :src="img.captchaImg"
          @click="acquireCaptcha"
          :fit="img.captchaFit"
          class="captcha"
          style="cursor: pointer; width: 140px; float: left; padding-left: 20px"
        />
      </el-form-item>
      <el-form-item
        label="手机号或邮箱"
        prop="phoneOrEmail"
        placeholder="请输入邮箱或者手机号"
      >
        <el-input v-model="account.to" style="width: 200px" />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="sendCode(account)"
          v-loading.fullscreen.lock="fullscreenLoading"
          >发送
        </el-button>
        <el-button @click="sendCode(account)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "../../Request";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      sendCodeStatus: (state) => state.Account.sendCodeStatus,
      token: (state) => state.Account.token,
    }),
  },
  data() {
    return {
      img: {
        captchaFit: "contain",
        captchaImg: "",
      },
      account: {
        to: "",
        captcha: "",
      },
      rules: {
        captcha: [
          {
            required: true,
            message: "请输入需要图形验证码",
            trigger: "change",
          },
        ],
        phoneOrEmail: [
          {
            required: true,
            message: "请输入需要注册的邮箱或者手机号",
            trigger: "clear",
          },
        ],
      },
      fullscreenLoading: false,
    };
  },
  methods: {
    acquireCaptcha() {
      axios
        .get("account-server/api/notify/v1/send_captcha", {
          responseType: "arraybuffer",
        })
        .then((res) => {
          this.img.captchaImg =
            "data:image/jpeg;base64," +
            btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
        });
    },
    sendCode(account) {
      if (this.token === "") {
        this.$store.dispatch("sendCode", account);
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
          console.log(this.sendCodeStatus);
          if (this.sendCodeStatus) {
            this.$router.push({ name: "register" });
          }
        }, 4000);
      } else {
        this.$message.error("登录状态，无法注册；如需注册请退出登录！");
      }
    },
  },
  created() {
    this.acquireCaptcha();
    if (this.token !== "") {
      this.$message.error("登录状态，无法注册；如需注册请退出登录！");
    }
  },
};
</script>

<style lang="scss" scoped></style>
